import { useEffect } from 'react';

function ScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array to run only once after mounting

  return null;
}

export default ScrollToTop;